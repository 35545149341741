import React from 'react';
import './Form.css';
import { useTranslation } from 'react-i18next';

const FormSuccess = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className='form-content-right'>
      <h1 className='form-success'>{t("contact.succes")}</h1>
    </div>
  );
};

export default FormSuccess;
