import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './SpecialMenu.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SpecialMenu = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <div className="app__specialMenu-tittle section__padding" id='about'>
        <SubHeading title={t("about.sub")} />
        <h1 className='app__header-h1' style={{ color: '#6afdf8' }}>{t("about.h1")}</h1>
      </div>
      <div className='app__specialMenu flex__center section__padding' >

        <div className="app__section1">
          <img src={images.web} alt="web" />
          <p className='p__opensans'>
            {t("about.web")}
          </p>
        </div>

        <div className="app__section2">
          <img src={images.wayF} alt="dot" />
        </div>

        <div className="app__section3">
          <img src={images.phone} alt="phone" />
          <p className='p__opensans'>
            {t("about.mobile")}
          </p>
        </div>

        <div className="app__section4">
          <img src={images.wayT} alt="dot" />
        </div>

        <div className="app__section5">
          <img src={images.social} alt="social" />
          <p className='p__opensans'>
            {t("about.social")}
          </p>
        </div>
      </div>

      <div className='app__specialMenu-tittle section__padding button__side'>
        <button type="button" className="button_slide slide_right" style={{ marginTop: '2rem', marginBottom: '2rem' }}><Link to="/contact">{t("about.button")}</Link></button>
      </div>
    </>

  );
}

export default SpecialMenu;
