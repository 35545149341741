import React from 'react';

import './Footer.css';
import { images } from '../../constants';
import { FaWhatsapp } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const [t, i18n] = useTranslation("global");

  const handleRedirect = () => {
      window.location.href = 'https://wa.me/05435200128';
  }


  return (
    <div className='app__footer app__bg'>
      <img src={images.footer} alt="foo" className='footer__background' />
      <div className="app__footer-links ">

        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">{t("footer.left")}</h1>
          <p className="p__opensans">{t("footer.pLeft")}</p>
          <p className="p__opensans">+90 543 520 01 28</p>
          <div className='app__footer-links_icons'>
            <FaWhatsapp onClick={handleRedirect} />
          </div>
        </div>

        <div className='app__footer-links_logo'>
          <img src={images.fooLogo} alt="footer_logo" />
          <p className="p__opensans">{t("footer.tag")}</p>

          <div className="footer__copyright">
            <p className="p__opensans">{t("footer.reserve")}</p>
          </div>
        </div>

        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">{t("footer.right")}</h1>
          <p className="p__opensans">{t("footer.mf")}</p>
          <p className="p__opensans">{t("footer.mfClock")}</p>
          <p className="p__opensans">{t("footer.ss")}</p>
          <p className="p__opensans">{t("footer.ssClock")}</p>

          <div className="footer__copyright_b">
            <p className="p__opensans">{t("footer.reserve")}</p>
          </div>
        </div>

      </div>

    </div>
  )
};

export default Footer;
