import React, { useState } from 'react';
import { MdClear, MdMenu } from 'react-icons/md';
import images from '../../constants/images';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import Flag from 'react-flagkit';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <img src={images.oniks} alt='app logo' />
      </div>
      <ul className='app__navbar-links'>
        <li className='p__opensans'><Link to="/">{t("nav.home")}</Link></li>
        <li className='p__opensans'><a href="#about">{t("nav.about")}</a></li>
        <li className='p__opensans'><a href="#service">{t("nav.service")}</a></li>
        <li className='p__opensans'><a href="#faq">{t("nav.faq")}</a></li>
        <li className='p__opensans'><Link to="/contact">{t("nav.cont")}</Link></li>
        <Flag country='US' style={{ marginRight: "0.5rem", cursor: "pointer" }} onClick={() => handleChangeLanguage("en")} />
        <Flag country='TR' style={{ cursor: "pointer" }} onClick={() => handleChangeLanguage("tr")} />
      </ul>

      <div className='app__navbar-smallscreen'>
        <MdMenu className='overlay__open' color='#d382ff' fontSize={27} onClick={() => setToggleMenu(true)} />

        {toggleMenu && (
          <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>

            <MdClear fontSize={27} className='overlay__close' onClick={() => setToggleMenu(false)} />

            <ul className='app__navbar-smallscreen-links'>
              <li className='p__opensans'><Link to="/">{t("nav.home")}</Link></li>
              <li className='p__opensans'><a href="#about">{t("nav.about")}</a></li>
              <li className='p__opensans'><a href="#service">{t("nav.service")}</a></li>
              <li className='p__opensans'><a href="#faq">{t("nav.faq")}</a></li>
              <li className='p__opensans'><Link to="/contact">{t("nav.cont")}</Link></li>
              <Flag country='US' style={{ marginLeft: "35%",marginRight: "1rem", cursor: "pointer" }} onClick={() => handleChangeLanguage("en")} />
              <Flag country='TR' style={{ cursor: "pointer" }} onClick={() => handleChangeLanguage("tr")} />
            </ul>
          </div>
        )}

      </div>
    </nav>
  );
}

export default Navbar;
