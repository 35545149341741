import React from 'react';
import { Data } from './Data';
import { DataTr } from './DataTr';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { SubHeading } from '../../components';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  height: 120vh;

  @media screen and (max-width: 1960px) {
    height: 90vh;
  }  
  @media screen and (max-width: 1920px) {
    height: 90vh;
  }
  @media screen and (max-width: 1850px) {
    height: 135vh;
  }
  @media screen and (max-width: 1366px) {
    height: 140vh;
  }
  @media screen and (max-width: 950px) {
    height: 140vh;
  }
  @media screen and (max-width: 850px) {
    height: 140vh;
  }
  @media screen and (max-width: 650px) {
    height: 135vh;
  }
  @media screen and (max-width: 630px) {
    height: 140vh;
  }
  @media screen and (max-width: 533px) {
    height: 145vh;
  }
  @media screen and (max-width: 525px) {
    height: 150vh;
  }
  @media screen and (max-width: 515px) {
    height: 155vh;
  }
`;
const Container = styled.div`
  position: absolute;
  top: 5%;
`;
const Wrap = styled.div`
  color: var(--color-aqua);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;

  h2 {
    padding: 1rem;
    font-size: 22px;
    font-family: var(--font-alt);
  }

  span {
    margin-right: 1.5rem;
    font-size: 24px;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 650px) {
    h2 {
      font-size: 19px;
    }
  }
`;

const Dropdown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 4rem;

  p {
    border-left: 1px solid #6afdf8;
    color: var(--color-white);
    font-family: var(--font-alt);
    font-size: 20px;
    max-width: 750px;
    padding: 0 1rem 0 2rem;
  }

  @media screen and (max-width: 650px) {
    p {
      font-size: 16px;
    }
  }
`;

const Laurels = () => {

  const [clicked, setClicked] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [changeData, setChangeData] = useState(false);

  useEffect(() => {
    if (i18n.language === "en") {
      setChangeData(true);
    } else if (i18n.language === "tr") {
      setChangeData(false);
    }
  }, [i18n.language]);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index)
  }

  return (

    <div className=' section__padding app__bg' id='faq'>
      <SubHeading title={t("faq.sub")} />
      <h1 className="app__header-h1" style={{ color: "#6afdf8" }}>
        {t("faq.h1")}
      </h1>

      <div className='app__bg'>
        <IconContext.Provider value={{ size: '32px' }}>
          <AccordionSection >
            <Container >
              {changeData ? Data.map((item, index) => {
                return (
                  <>
                    <Wrap onClick={() => toggle(index)} key={index}>
                      <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                      <h2>{item.question}</h2>
                    </Wrap>
                    {clicked === index ? (
                      <Dropdown>
                        <p>{item.answer}</p>
                      </Dropdown>
                    ) : null}
                  </>
                )
              }) : DataTr.map((item, index) => {
                return (
                  <>
                    <Wrap onClick={() => toggle(index)} key={index}>
                      <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                      <h2>{item.question}</h2>
                    </Wrap>
                    {clicked === index ? (
                      <Dropdown>
                        <p>{item.answer}</p>
                      </Dropdown>
                    ) : null}
                  </>
                )
              })
            }
            </Container>
          </AccordionSection>
        </IconContext.Provider>
      </div>

    </div>

  )
}

export default Laurels;
