import React from 'react';

import { images } from '../../constants'


const SubHeading = ({title}) => (
  <div style={{marginBottom: '1rem'}}>
    <p className="p__cormorant" style={{color: "#b4ff00"}}>
      {title}
    </p>
    <img src={images.code} alt="code" className='spoon__img'  />
  </div>
);

export default SubHeading;
