import React, { useState } from 'react';
import './Form.css';
import FormSignup from './FormSignup';
import FormSuccess from './FormSuccess';
import img2 from './img/icon-reputation.svg'
import { Navbar } from '../../components';
import Footer from '../Footer/Footer';


const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  return (
    <>
    <Navbar />
    <div className='app__bg flex__center section__padding_form'>
      <div className='form-container'>
        <div className='form-content-left'>
        <img className='form-img' src={img2} alt='spaceship' />
        </div>
        {!isSubmitted ? (
          <FormSignup submitForm={submitForm} />
        ) : (
          <FormSuccess />
        )}
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Form;
