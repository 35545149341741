export default function validateInfo(values) {
  let errors = {};

  if (!values.username.trim()) {
    errors.username = 'Name required';
  }
  else if (!/^[A-Za-z]+/.test(values.username.trim())) {
    errors.username = 'Enter a valid name';
  }

  if (!values.email) {
    errors.email = 'E-mail required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'E-mail address is invalid';
  }
  if (!values.password) {
    errors.password = 'Message is required';
  } else if (values.password.length < 2) {
    errors.password = 'Please send meaningful message';
  }

  return errors;
}
