import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';
import App from './App';
import Form from './container/Contact/Form';

import global_en from "./translations/en/global.json";
import global_tr from "./translations/tr/global.json";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
    interpolation: {escapeValue: false},
    lng: "tr",
    resources: {
        en: {
            global: global_en,
        },
        tr: {
            global: global_tr,
        },
    },
})


const router = createBrowserRouter([
    {
        path: "/",
        element:<App />
    },
    {
        path: "contact",
        element:<Form />
    }
])

ReactDOM.render(<I18nextProvider i18n={i18next}><RouterProvider router={router}/></I18nextProvider>  , document.getElementById('root'));

