import React from 'react';
import validate from './validateInfo';
import useForm from './useForm';
import './Form.css';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const FormSignup = ({ submitForm }) => {
  const [t, i18n] = useTranslation("global");

  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ds6ndvm', 'template_h2uj7uw', form.current, 'QJm5RPh5GYuEDb4Uc')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      handleSubmit(e);
  }

  return (
    <div className='form-content-right'>
      <form ref={form} onSubmit={sendEmail} className='form' noValidate>
        <h1>
          {t("contact.h1")}
        </h1>
        <div className='form-inputs'>
          <label className='form-label'>{t("contact.name")}</label>
          <input
            className='form-input'
            type='text'
            name='username'
            placeholder={t("contact.nameHolder")}
            value={values.username}
            onChange={handleChange}
          />
          {errors.username && <p>{errors.username}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>{t("contact.email")}</label>
          <input
            className='form-input'
            type='email'
            name='email'
            placeholder={t("contact.emailHolder")}
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p>{errors.email}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>{t("contact.message")}</label>
          <textarea
            className='form-input'
            style={{height: "120px", overflow: "auto", resize: "none"}}
            type='mesage'
            name='password'
            placeholder={t("contact.messageHolder")}
            value={values.password}
            onChange={handleChange}
          />
          {errors.password && <p>{errors.password}</p>}
        </div>
        <button className='button_slide_aqua slide_right_aqua' type='submit' >
          {t("contact.button")}
        </button>
      </form>
    </div>
  );
};

export default FormSignup;
