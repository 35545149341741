import React from 'react';
import { images } from '../../constants'
import './Chef.css';
import { SubHeading } from '../../components';
import Marquee from "react-fast-marquee";
import { useTranslation } from 'react-i18next';

const Chef = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <div className='app__wrapper-section section__padding app__bg' id='service' >
        <div className='app__wrapper_info app__services-title'>
          <SubHeading title={t("service.sub")} />
          <h1 className="app__header-h1">{t("service.h1")}</h1>
          <p className='p__opensans' style={{ margin: "2rem 0", maxWidth: '650px' }}>
            {t("service.p")}
          </p>
          <button type="button" style={{ marginTop: '1rem' }} className="button_slide_purple slide_right_purple" onClick={event => window.location.href = "#faq"}>{t("service.button")}</button>
        </div>

        <div className="app__chef_img">
          <img src={images.mouse} alt="mouse" />
        </div>
      </div>
      <div className='app__bg marquee_padding'>
        <div className="rectange ">
          <div className='marquee__text'>
            <Marquee speed={50}  >
              &nbsp;Python  ●  Java  ●  React  ●  React Native  ●  Angular  ●  NextJS  ●  Flask  ●  Spring  ●  ASP.NET  ●  MongoDB  ●  MySQL  ●  Postgres  ●  SQL  ●  Hibernate  ●  Firebase  ●  PHP  ●  SQLite  ●  ElasticSearch  ●  Docker  ●
            </Marquee>
          </div>
        </div>
      </div>
    </>

  );
}

export default Chef;
