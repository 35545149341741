import React from 'react';
import { Link } from 'react-router-dom';
import './Intro.css';
import { useTranslation } from 'react-i18next';

const Intro = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <div className='app__wrapper-section_intro section__padding app__bg' id='services' >
        <div className='app__wrapper_info app__services-title'>
          <h1 className="app__header-h1" style={{ color: "#b4ff00" }}>
            {t("subService.h1")}
          </h1>
          <p className='p__opensans' style={{ margin: "2rem 0", maxWidth: '650px' }}>
           {t("subService.p1")}
            <br />
            <br />
            {t("subService.p2")}
          </p>
          <button type="button" style={{ marginTop: '1rem' }} className="button_slide slide_right"><Link to="/contact">{t("subService.button")}</Link></button>
        </div>

        <div class="atom">
          <div class="line line-1"></div>
          <div class="line line-2"></div>
          <div class="line line-3"></div>
        </div>

      </div>
    </>
  );
}
export default Intro;
