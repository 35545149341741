import React, { useRef } from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { images } from '../../constants';
import './Gallery.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';


const galleryImgUs = [
  images.gallery01,
  images.gallery02,
  images.gallery03,
  images.gallery04
]

const galleryImgTr = [
  images.gallery01_tr,
  images.gallery02_tr,
  images.gallery03_tr,
  images.gallery04_tr
]

const Gallery = () => {
  const scrollRef = useRef(null);
  const [t, i18n] = useTranslation("global");
  const [changeImage, setChangeImage] = useState(false);

  useEffect(() => {
    if (i18n.language === "en") {
      setChangeImage(true);
    } else if (i18n.language === "tr") {
      setChangeImage(false);
    }
  }, [i18n.language]);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') { 
      current.scrollLeft -= 305;
    } else if (direction === 'right') {
      current.scrollLeft += 305;
    }
  }

  return (
    <div className='app__gallery flex__center'>
      <div className="app__gallery-content">
        <h1 className='app__header-h1'>{t("gallery.h1")}<span className='color__change'>{t("gallery.span")}</span></h1>
        <p className='p__opensans' style={{maxWidth: "650px"}}>
          {t("gallery.p")}
        </p>
        <button type="button" className="button_slide_purple slide_right_purple"><Link to="/contact">{t("gallery.button")}</Link></button>
      </div>

      <div className='app__gallery-images'>
        <div className="app__gallery-images_container" ref={scrollRef}>
          {changeImage ? galleryImgUs.map((img, index) => (
            <div className="app__gallery-images-card flex__center" key={`gallery_image-${index + 1}`}>
              <img src={img} alt="gallery" />
            </div>
          )) : galleryImgTr.map((img, index) => (
            <div className="app__gallery-images-card flex__center" key={`gallery_image-${index + 1}`}>
              <img src={img} alt="gallery" />
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrow">
          <BsArrowLeftShort className='gallery__arrow-icon' onClick={() => scroll('left')} />
          <BsArrowRightShort className='gallery__arrow-icon' onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
