export const Data = [
    {
        question: 'What is Oniks Tech?',
        answer: "// Oniks Tech is an on-demand software company. It doesn't just finish your web or mobile projects and step aside; it turns your project into a story and provides you with personalized digital marketing."
    },
    {
        question: 'How are you different from the other freelance platforms?',
        answer: "// The designs we make always tell a story, they are not just lines of code. Whatever your project is, we will start you 10x ahead for advertising. Since our project processes are customer-oriented, the process is stress-free. No more antidepressants.."
    },    
    {
        question: 'Is there a minimum project duration?',
        answer: "// Small or large scale projects do not make a difference for us. Many customers continue to use Oniks Tech developers for years!"
    },
    {
        question: 'How does your billing work?',
        answer: '// After you accept our offer for your project, you will be charged a one-time security fee. This is usually 30% of the amount offered in the project. The remaining fee is allocated at the end of the project or in monthly periods.'
    },
    {
        question: 'How long does it take to charge?',
        answer: '// Your project will be examined by our team according to the information you have provided, and our offer along with the cost table will be sent to you within 3 days at the latest.'
    },
    {
        question: 'Will I make payment after project delivery?',
        answer: '// If you have agreed to receive monthly maintenance in the project proposal, maintenance work will be carried out according to your wishes for the period you want and the agreed fee will be allocated every month. You can opt out of monthly maintenance at any time.'
    },
    {
        question: 'How does billing work to different countries?',
        answer: '// Billing is Eu-Compatible so refunds and payments are no problem.'
    },
    {
        question: 'Will I get a project estimation before the start?',
        answer: "// Of course give us the requirements and details on your projects, and we'll crunch the numbers to tell you how many hours the work takes. You can use this number to project the total cost."
    },
    {
        question: 'What technologies do you work with?',
        answer: "// We commonly have react, angular, react native, javascript, nodejs, java, .net, python, flutter and swift on tap. But go ahead and let us know what particular stack you are looking for."
    },
    {
        question: 'How long does it take to start the project?',
        answer: "// It depends on the scope and requirements of your projects. After you accept our offer, your project will start within 2 days and the stages will be notified to you via e-mail."
    },
    {
        question: 'What if I give up?',
        answer: "// Unless there are days left for your project to be completed, you can give up on the project and cancel the payment plan. If there is a fee we need to refund, we will make the refund within 1 week."
    },


]