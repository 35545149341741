import React from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../constants'
import { SubHeading } from '../../components';
import './Header.css';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className='app__header app__wrapper section__padding' id='home'>
      <div className='app__wrapper_info'>
        <SubHeading title={t("header.sub")}/>
        <h1 className="app__header-h1">{t("header.h1")}</h1>
        <h1 className="app__header-h2">{t("header.h2")}</h1>
        <p className='p__opensans' style={{ margin: "2rem 0" }}>{t("header.p")}</p>
        <button type="button" className="button_slide slide_right"><Link to="/contact">{t("header.button")}</Link></button>
      </div>

      <div className="app__wrapper_img">
        <img src={images.header} alt="header" />
      </div>
    </div>
  );
}



export default Header;
