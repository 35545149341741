export const DataTr = [
    {
        question: 'Oniks Tech nedir?',
        answer: "// Oniks Tech, talep üzerine çalışan yazılım şirketidir. Sadece web veya mobil projelerinizi bitirip kenara çekilmez; projenizi hikayeye dönüştürür ve size kişiselleştirilmiş dijital pazarlama sunar."
    },
    {
        question: 'Diğer freelance platformlardan farkınız nedir?',
        answer: "// Yaptığımız tasarımlar her zaman bir hikaye anlatır, sadece kod satırlarından ibaret değildir. Projeniz ne olursa olsun, pazarlama konusunda sizi 10 kat önde başlatacağız. Proje süreçlerimiz müşteri odaklı olduğundan süreç stressizdir. Artık antidepresan yok.."
    },    
    {
        question: 'Minimum proje süresi var mı?',
        answer: "// Küçük ya da büyük ölçekli projeler bizim için fark yaratmıyor. Birçok müşteri yıllardır Oniks Tech geliştiricilerini kullanmaya devam ediyor!"
    },
    {
        question: 'Faturalandırmanız nasıl çalışıyor?',
        answer: "// Projeniz için teklifimizi kabul ettiğinizde sizden bir defaya mahsus güvenlik ücreti tahsil edilecektir. Bu genellikle projede teklif edilen miktarın %30'udur. Kalan ücret proje sonunda veya aylık periyotlarda tahsis edilir."
    },
    {
        question: 'Gönderdiğimiz projenin değerlendirilmesi ne kadar sürer?',
        answer: '// Projeniz verdiğiniz bilgiler doğrultusunda ekibimiz tarafından incelenecek ve maliyet tablosuyla birlikte teklifimiz en geç 3 gün içerisinde tarafınıza iletilecektir.'
    },
    {
        question: 'Proje tesliminden sonra ödeme yapacak mıyım?',
        answer: '// Proje teklifinde aylık bakım almayı kabul ettiyseniz istediğiniz süre boyunca istekleriniz doğrultusunda bakım çalışması yapılacak ve her ay anlaşılan ücret tahsis edilecektir. İstediğiniz zaman aylık bakımdan vazgeçebilirsiniz.'
    },
    {
        question: 'Faturalandırma farklı ülkelerde nasıl işliyor?',
        answer: '// Faturalandırma AB standartları ile uyumludur, bu nedenle geri ödemeler ve ödemeler sorun değildir.'
    },
    {
        question: 'Başlamadan önce proje planlaması alacak mıyım?',
        answer: "// Elbette bize projelerinizin gerekliliklerini ve ayrıntılarını verin, biz de size işin kaç saat sürdüğünü söylemek için rakamları hesaplayalım. Toplam maliyeti tahmin etmek için bu sayıyı kullanabilirsiniz."
    },
    {
        question: 'Hangi teknolojilerle çalışıyorsunuz?',
        answer: "// Genellikle react, angular, react native, javascript, nodejs, java, .net, python, flutter ve swift kullanıyoruz. Ancak sormaktan çekinmeyin ve hangi teknolojiyi aradığınızı bize bildirin."
    },
    {
        question: 'Projeye başlamak ne kadar sürer?',
        answer: "// Projelerinizin kapsamına ve gereksinimlerine bağlıdır. Teklifimizi kabul etmenizin ardından 2 gün içerisinde projeniz başlayacak ve aşamalar tarafınıza e-posta yoluyla bildirilecektir."
    },
    {
        question: 'Peki ya vazgeçersem?',
        answer: "// Projenizin tamamlanmasına günler kalmadığı sürece projeden vazgeçebilir, ödeme planını iptal edebilirsiniz. İade etmemiz gereken bir ücret varsa 1 hafta içinde iadeyi yaparız."
    },


]