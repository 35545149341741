import bg from '../assets/bg.webp';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import gallery01_tr from '../assets/gallery01_tr.png';
import gallery02_tr from '../assets/gallery02_tr.png';
import gallery03_tr from '../assets/gallery03_tr.png';
import gallery04_tr from '../assets/gallery04_tr.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import oniks from '../assets/oniks.png';
import code from '../assets/code-32.png';
import header from '../assets/header.png';
import brain from '../assets/brain.png';
import hand from '../assets/hand.png';
import clock from '../assets/clock.png';
import web from '../assets/web.png';
import phone from '../assets/phone.png';
import social from '../assets/social.png';
import wayF from '../assets/wayf.png';
import wayT from '../assets/wayt.png';
import wayH from '../assets/wayth.png';
import mouse from '../assets/mouse.png';
import footer from '../assets/footer.png';
import fooLogo from '../assets/foo_logo.png';



export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  oniks,
  code,
  header,
  brain,
  hand,
  clock,
  web,
  phone,
  social,
  wayF,
  wayH,
  wayT,
  mouse,
  footer,
  fooLogo,
  gallery01_tr,
  gallery02_tr,
  gallery03_tr,
  gallery04_tr
};
