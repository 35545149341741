import React from 'react';
import { images } from '../../constants'
import './AboutUs.css';
import { useTranslation } from 'react-i18next';


const AboutUs = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className='app__aboutus app__bg flex__center section__padding'>
      <div className='app__aboutus-content flex__center'>

        <div className='app__aboutus-content_brain'>
          <img src={images.brain} alt="brain" />
          <p className='p__opensans'>{t("aboutus.brain")}</p>
        </div>

        <div className='app__aboutus-content_hand' >
          <img src={images.hand} alt="hand" />
          <p className='p__opensans'>{t("aboutus.hand")}<br></br>{t("aboutus.br")}</p>
        </div>

        <div className='app__aboutus-content_clock'>
          <img src={images.clock} alt="clock" />
          <p className='p__opensans'>{t("aboutus.clock")}</p>
        </div>

      </div>
    </div>
  );
}

export default AboutUs;
